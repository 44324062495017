import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import '../index.css';
import { isMobile } from 'react-device-detect';

const AboutSection = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 44px;

    @media only screen and (max-width: 768px) {
        grid-gap: 10px 30px;
    }
`;

const Header = styled.p`
    grid-column: 2 / span 4;
    text-align: justify;

    @media only screen and (max-width: 768px) {
        grid-column: 1 / span 6;
    }
`;

const ProfilePicture = styled.div`
    grid-column: 2;
    min-width: 200px;
    @media only screen and (max-width: 768px) {
        grid-column: span 6;
    }
`;

const Name = styled.h2`
    /* text-align: right; */
    /* margin: 15px 10px 0 0; */
    margin-top: 0;
    @media only screen and (max-width: 768px) {
        text-align: center;
        margin-top: 20px;
    }
`;

const Description = styled.div`
    grid-column: span 3;
    text-align: justify;
    @media only screen and (max-width: 768px) {
        grid-column: span 6;
    }
`;

const Inquiries = styled.div`
    text-align: center;
    margin: 50px 0 0;

    a {
        font-weight: bold;
        text-decoration: none;
        color: inherit;
    }

    @media only screen and (max-width: 768px) {
        margin-top: 30px;
    }
`;

// markup
const ContactPage = ({ data }: { data: any }) => {
    return (
        <>
            <Helmet title="Contact | Greative" defer={false}>
                <meta
                    name="description"
                    content="Multidisciplinary Design Studio of Alex Bălăiță and Zumzzet."
                />
            </Helmet>
            <AboutSection>
                <Header>
                    We’ve started Greative in 2013, out of passion for
                    delivering stunning visuals and telling compelling stories.
                    Since then, we’ve worked with over 300 international
                    clients. We combine design knowledge and experience, a
                    business oriented mindset and a touch of magic to create
                    brands that work in the attention economy.
                </Header>
                {data.allContentfulMember.edges.map(
                    (member: any, i: number) => {
                        const image = getImage(member.node.profilePicture);
                        return (
                            <React.Fragment key={i}>
                                <ProfilePicture>
                                    {!isMobile && image && (
                                        <GatsbyImage alt="" image={image} />
                                    )}
                                </ProfilePicture>
                                <Description>
                                    <Name>{member.node.name}</Name>
                                    {isMobile && image && (
                                        <ProfilePicture>
                                            <GatsbyImage alt="" image={image} />
                                        </ProfilePicture>
                                    )}
                                    <p>{member.node.description.description}</p>
                                </Description>
                            </React.Fragment>
                        );
                    }
                )}
            </AboutSection>
            <Inquiries>
                <p>
                    For inquiries, drop us a line:{' '}
                    <a href="mailto:projects@greative.com">
                        projects@greative.com
                    </a>
                </p>
            </Inquiries>
        </>
    );
};

export default ContactPage;

export const query = graphql`
    query {
        allContentfulMember {
            edges {
                node {
                    name
                    profilePicture {
                        gatsbyImageData
                    }
                    description {
                        description
                    }
                }
            }
        }
    }
`;
